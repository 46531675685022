import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { filterOutDocsPublishedInTheFuture, filterOutDocsWithoutSlugs, mapEdgesToNodes } from '../utils/bloghelpers'
import BlogPostPreviewList from '../components/blog/blog-post-preview-list'
import { Navbar } from '../components/navbar'
import SEO from '../components/seo'
import Layout from '../components/layout'
import styled from '@emotion/styled'
import Footer from '../components/footer/footer.component'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import queryString from 'query-string'
import Author from '../components/blog/author'
import slugify from 'slugify'

export const query = graphql`
	query BlogPageQuery {
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
		site: allSiteSetting(limit: 1) {
			nodes {
				id
				title
				description
				keywords
			}
		}
		posts: allPost(
			filter: { id: { ne: null }, publishedAt: { ne: null }, slug: { objectId: { ne: null } } }
			sort: { fields: publishedAt, order: DESC }
		) {
			edges {
				node {
					id
					publishedAt
					lang
					mainImage {
						alt
						idengagerfile {
							name
							url
						}
						externalurl
					}
					title
					slug {
						current
					}
					categories {
						title
					}
					authors {
						name
						bio {
							children {
								text
							}
						}
						image {
							externalurl
							alt
							idengagerfile {
								name
								url
							}
						}
					}
				}
			}
		}
		authors: allAuthor {
			nodes {
				name
				id
				country
				bio {
					children {
						text
					}
				}
				image {
					externalurl
				}
			}
		}
	}
`

const BlogPage = props => {
	const { data, errors, location } = props

	const site = (data || {}).site?.nodes && (data || {}).site?.nodes[0]
	const postNodes = (data || {}).posts.edges
	const authorsModes = (data || {}).authors.nodes

	const [post, setPost] = useState(postNodes)

	const value = queryString.parse(location.search)
	const category = value.category
	const authorSelected = value.author

	const arabicPosts = post.filter(({ node }) => {
		return node.lang === 'ar'
	})

	const englishPosts = post.filter(({ node }) => {
		return node.lang === null
	})

	const author = authorsModes.find(e => e.name === authorSelected?.replace(/[-]/g, ' '))

	useEffect(() => {
		if (category) {
			const ConvertedCategory = category?.replace(/[-]/g, ' ')

			setPost(
				post.filter(({ node }) => {
					return node.categories.find(cat => cat.title.trim() === ConvertedCategory)
				})
			)
		} else if (authorSelected) {
			const ConvertedAuthor = authorSelected?.replace(/[-]/g, ' ')

			setPost(
				post.filter(({ node }) => {
					return node.authors.find(cat => cat.name.trim() === ConvertedAuthor)
				})
			)
		} else if (location.pathname === '/ar/blogs') {
			setPost(arabicPosts)
		} else {
			setPost(englishPosts)
		}
	}, [category, authorSelected])

	if (errors) {
		return (
			<Layout>
				<h1>Error {errors}</h1>
				{/* <GraphQLErrorList errors={errors} /> */}
			</Layout>
		)
	}

	if (!site) {
		throw new Error(
			'Missing "Site settings". Open the contentmanager at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
		)
	}
	return (
		<Layout>
			<SEO
				title={
					category
						? `Blogs for ${category} category `
						: authorSelected
						? `Blogs for ${authorSelected} author`
						: 'The Definitive Encyclopedia of Property Management Resources'
				}
				keywords={[`property management`]}
				description={
					category
						? `iCloud is ready to provide you with the largest encyclopedia of information about all the questions you have in mind about property management - ${category}`
						: authorSelected
						? `iCloud is ready to provide you with the largest encyclopedia of information about all the questions you have in mind about property management - ${authorSelected}`
						: 'iCloud is ready to provide you with the largest encyclopedia of information about all the questions you have in mind about property management'
				}
			/>
			<div className="home-page">
				<Navbar />
				<BlogContainer>
					<h2>
						{category
							? `Blogs for category: ${category}`
							: authorSelected
							? `Blogs for author: ${authorSelected}`
							: 'Recent Blogs'}
					</h2>
					{authorSelected && author && <Author author={author} />}
					{post && <BlogPostPreviewList nodes={post} />}
				</BlogContainer>
				<div className="footer-section">
					<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
				</div>
			</div>
		</Layout>
	)
}

export default BlogPage

const BlogContainer = styled.div`
	width: 100%;
	margin: 8rem auto;
	padding: 0 9.5vw;
	@media only screen and (min-width: 320px) and (max-width: 1200px) {
		padding: 28px 15px 0;
	}
`
