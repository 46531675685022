/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'

function SEO({ description, lang, meta, keywords, title, image }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
						sitemap
					}
				}
			}
		`
	)

	const location = useLocation()
	//   const { t, i18n } = useTranslation()
	const metaDescription = description || site.siteMetadata.description
	const defaultTitle = site.siteMetadata?.title
	const metaImage = image || `${site.siteMetadata.siteUrl}/logo.png`

	return (
		<Helmet
			// htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }}
			// bodyAttributes={{ lang: i18n.language, dir: i18n.dir() }}
			htmlAttributes={{
				lang,
			}}
			title={title || defaultTitle}
			titleTemplate={title ? title : `${defaultTitle}`}
			link={[
				{
					rel: 'sitemap',
					type: 'application/xml',
					href: site.siteMetadata.sitemap,
				},
			]}
			script={[
				{
					type: 'text/javascript',
					innerHTML: `!function(s,n,i,t,c,h){s.SnitchObject=i;s[i]||(s[i]=function(){
					(s[i].q=s[i].q||[]).push(arguments)});s[i].l=+new Date;c=n.createElement(t);
					h=n.getElementsByTagName(t)[0];c.src='//snid.snitcher.com/8420955.js';
					h.parentNode.insertBefore(c,h)}(window,document,'snid','script');
						
					snid('verify', '8420955');
					`,
				},
			]}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: `${title || defaultTitle}`,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: metaImage,
				},
				{
					property: `og:image:type`,
					content: `image/png`,
				},
				{
					property: `og:image:width`,
					content: `257`,
				},
				{
					property: `og:image:height`,
					content: `130`,
				},
				{
					property: `og:image:alt`,
					content: `Icloud ready logo`,
				},
				{
					name: 'og:url',
					content: `${site?.siteMetadata?.siteUrl}${location.pathname}`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata?.author || ``,
				},
				{
					name: `twitter:title`,
					content: `${title || defaultTitle}`,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					property: `twitter:image:alt`,
					content: `Icloud ready logo`,
				},
				{
					property: `twitter:image`,
					content: metaImage,
				},
			]
				.concat(meta)
				.concat(
					keywords.length > 0
						? {
								name: `keywords`,
								content: keywords.join(`, `),
						  }
						: []
				)}
		/>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
}

export default SEO
