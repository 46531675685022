import React from 'react'
import * as styles from './author.module.css'

const Author = ({ author }) => {
 const { id, name, country, image: { externalurl, alt }, bio } = author
 return (
  <div className={styles.container}>
   <div>
    <img src={externalurl} alt={alt} width={200} height={200} />
   </div>
   <div className={styles.info}>
    <p>Name: {name}</p>
    <p>Country: {country}</p>
    <p>Bio: {bio[0].children.map(d => <span>{d.text}</span>)}</p>
   </div>
  </div>
 )
}

export default Author