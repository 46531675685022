import * as styles from './blog-post-preview.module.css'
import { buildImageObj, cn, getBlogUrl } from '../../utils/bloghelpers'
import { Link } from 'gatsby'
// import PortableText from "./portableText";
import React from 'react'
import { format } from 'date-fns'
import { imageUrlFor } from '../../utils/image-url'
import { responsiveTitle3 } from './typography.module.css'
import { Button } from 'antd'

function BlogPostPreview(props) {
	const { mainImage, title, publishedAt, slug, lang } = props.node

	return (
		<li className={styles.blogDetails}>
			<div className={styles.blogImage}>
				{mainImage && mainImage.idengagerfile && (
					<img src={imageUrlFor(mainImage.externalurl)} alt={mainImage.alt} />
				)}
			</div>
			<div className={styles.blogInfo}>
				<h3 className={cn(responsiveTitle3, styles.title)}>{title}</h3>
				{props._rawExcerpt && (
					<div className={styles.excerpt}>{/* <PortableText blocks={props.excerpt} /> */}</div>
				)}
				<div className={styles.date}>{format(new Date(publishedAt), 'MMMM Mo, yyyy')}</div>
				<Link to={getBlogUrl(publishedAt, slug, lang)}>
					<Button
						style={{ backgroundColor: '#0097c2' }}
						type="primary"
						shape="round"
						size="large"
					>
						Read More
					</Button>
				</Link>
			</div>
		</li>
	)
}

export default BlogPostPreview
