// extracted by mini-css-extract-plugin
export var title1 = "typography-module--title1--2QGj7";
export var title2 = "typography-module--title2--2iMhv";
export var title3 = "typography-module--title3--1Tboj";
export var large = "typography-module--large--33pNL";
export var base = "typography-module--base--35IWj";
export var small = "typography-module--small--1SU-S";
export var micro = "typography-module--micro--TBzn9";
export var paragraph = "typography-module--paragraph--3OWYW";
export var blockQuote = "typography-module--blockQuote--2mPBl";
export var responsiveTitle1 = "typography-module--responsiveTitle1--qBuKa";
export var responsiveTitle2 = "typography-module--responsiveTitle2--27E_s";
export var responsiveTitle3 = "typography-module--responsiveTitle3--2gu3r";
export var responsiveTitle4 = "typography-module--responsiveTitle4--U2dJc";