import * as styles from './blog-post-preview-list.module.css'
import BlogPostPreview from './blog-post-preview'
import { Link } from 'gatsby'
import React from 'react'

function BlogPostPreviewGrid({ nodes }) {
	console.log('from blog post grid nodes', nodes)
	return (
		<>
			<ul className={styles.blogList}>
				{nodes && nodes.map(node => <BlogPostPreview {...node} key={node.id} />)}
			</ul>
		</>
	)
}

BlogPostPreviewGrid.defaultProps = {
	title: '',
	nodes: [],
	browseMoreHref: '',
}

export default BlogPostPreviewGrid
